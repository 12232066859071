<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2d"
            defaultEnd="6d"
            label="작업계획일"
            name="plantDts"
            v-model="searchParam.plantDts"
          />
        </div>
        <div class="col-6">
          <c-dept type="search" label="작업요청 부서" name="reqDeptCd" v-model="searchParam.reqDeptCd" :isFirstValue="false" />
        </div>
        <div class="col-6">
          <c-dept type="search" label="정비오더 발행부서" name="workDeptCd" v-model="searchParam.workDeptCd" />
        </div>
        <div class="col-6">
          <c-select
            codeGroupCd="WO_WORK_PLAN_STEP_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="woWorkPlanStepCd"
            label="진행상태"
            v-model="searchParam.woWorkPlanStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" @click="linkClick(props.row)">
              <q-card class="mobileTableCardLayer">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColors(props.row.woWorkPlanStepCd)]">
                    {{ props.row.woWorkPlanStepName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.workPlanName }} 
                  </div>
                </q-card-section>
                <q-card-section class="grid/7-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.woWorkTypeName }} | {{ props.row.workDeptName }} | {{ props.row.planDtsStr }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDts: [],
        plantDts: [],
        workDeptCd: '',
        reqDeptCd: '',
        woWorkPlanStepCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'woWorkPlanStepCd',
            field: 'woWorkPlanStepCd',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          {
            fix: true,
            name: 'workPlanName',
            field: 'workPlanName',
            label: '정비오더명',
            align: 'left',
            sortable: true,
            style: 'width:300px',
            type: 'link'
          },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '위치',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: true,
          // },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '대표설비Tag',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '정비오더 발행부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woWorkTypeName',
            field: 'woWorkTypeName',
            label: '작업유형',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'planDtsStr',
            field: 'planDtsStr',
            label: '작업계획일',
            align: 'center',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'resultDtsStr',
            field: 'resultDtsStr',
            label: '실제작업일',
            align: 'center',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'reqDeptName',
            field: 'reqDeptName',
            label: '작업요청 부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          // {
          //   name: 'woRequestDt',
          //   field: 'woRequestDt',
          //   label: '요청일',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          // {
          //   name: 'copywork',
          //   field: 'copywork',
          //   label: '계획복사',
          //   align: 'center',
          //   style: 'width:80px',
          //   type: 'custom',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.wod.workorder.plan.list.url;
      this.copyUrl = transactionConfig.wod.workorder.plan.copy.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = row ? '정비오더 상세 - [' + row.woWorkPlanStepName  + ']': '정비오더 상세';
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        workResultId: row ? row.workResultId : '',
        woRequestId: row ? row.woRequestId : '',
        copyFlag: row ? row.copyFlag : 'N',
        firstCopy: row ? (row.firstCopy ? row.firstCopy : false) : false,
      };
      this.popupOptions.target = () => import(`${'./workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 작업요청
          returnColor = 'txt-box-grid text-gray-box';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'txt-box-grid text-orange-box';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'txt-box-grid text-primary-box';
          break;
        case 'WWPSC00004': // 작업결과 승인중
          returnColor = 'txt-box-grid text-blue-box';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'txt-box-grid text-accent-box';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'txt-box-grid text-positive-box';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'txt-box-grid text-red-box';
          break;
      }

      return returnColor;
    },
    copyWorkPlan(_workPlanId, _plantCd) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '선택된 작업계획정보를 복사하여\r\n신규 작업계획을 수립하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.copyUrl;
          this.$http.type = 'POST';
          this.$http.param = {
            woWorkPlanStepCd: 'WWPSC00001', // 작업계획
            regUserId: this.$store.getters.user.userId,
            originalWorkPlanId: _workPlanId,
            plantCd: _plantCd,
          };
          this.$http.request((result) => {
            this.openPop({
              workPlanId: result.data.workPlanId,
              plantCd: result.data.plantCd,
              woWorkPlanStepCd: result.data.woWorkPlanStepCd,
              woRequestId: result.data.woRequestId,
              copyFlag: 'Y',
              firstCopy: true,
            });
            console.log('result',)
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
